import {
  DataSourceTableConfig,
  ExploreUserJoin,
  ExploreUserJoinTable,
  MagicEventStatus,
  SemanticDatasetName,
  tablePathToDatasetName,
} from "@hex/common";
import { useEffect, useMemo } from "react";
import { shallowEqual } from "react-redux";

import { exploreSpecActions } from "../explore-v2/data/editExploreSpecReducer.js";
import { useUpdateExploreSpec } from "../explore-v2/hooks/useUpdateExploreSpec.js";
import { ExistingJoinConfig } from "../explore-v2/joins/ExploreConfigureJoin.js";
import {
  useJoinValidation,
  useUniqueKeyValidation,
} from "../explore-v2/joins/exploreConfigureJoinValidation.js";
import { useCellContentsSelector } from "../hex-version-multiplayer/state-hooks/cellContentsStateHooks.js";
import { useLatestMagicEventForCellSelector } from "../hex-version-multiplayer/state-hooks/magicEventStateHooks.js";
import { useDispatch } from "../redux/hooks.js";
import { MagicEventMP } from "../redux/slices/hexVersionMPSlice.js";
import {
  MagicExploreValidationResult,
  magicActions,
} from "../redux/slices/logicViewSlice.js";
import { useCellContext } from "../util/cellContext.js";

import { useUpdateExploreValidation } from "./useUpdateExploreValidation.js";

export const useValidateMagicJoin = ({
  queryPath,
}: {
  queryPath: SemanticDatasetName[];
}): {
  hasTimeout: boolean;
  hasError: boolean;
  isLoading: boolean;
  hasMagicGeneratedJoin: boolean;
  existingJoinConfig?: ExistingJoinConfig;
} => {
  const cellId = useCellContext((c) => c.cellId);
  const dispatch = useDispatch();
  const updateMagicJoinValidation = useUpdateExploreValidation(cellId);
  const updateExploreSpec = useUpdateExploreSpec(cellId);

  const latestMagicEventStatus = useLatestMagicEventForCellSelector({
    cellId,
    selector: (magicEventState: MagicEventMP | undefined) =>
      magicEventState?.status,
    safe: true,
  });

  const { dataframe, spec } = useCellContentsSelector({
    cellId,
    selector: (contents) => {
      if (contents.__typename !== "ExploreCell") {
        throw new Error(`Expected ExploreCell, got ${contents.__typename}`);
      }

      const exploreDataframe = contents.exploreDataframe;
      const exploreSpec = contents.spec;
      return {
        // Magic can only generate joins between warehouse tables. If not a
        // warehouse table then we can skip all validation.
        dataframe: DataSourceTableConfig.guard(exploreDataframe)
          ? exploreDataframe
          : null,
        spec: exploreSpec,
      };
    },
    equalityFn: shallowEqual,
  });

  const {
    isLoading: isJoinValidationLoading,
    isValidated: isJoinValidated,
    joinTestResult,
    relationshipType,
    startJoinValidation,
  } = useJoinValidation();

  const {
    isLoading: targetTableUniqueKeyIsLoading,
    isValidated: targetTableIsValidated,
    startUniqueKeyValidation: startTargetTableUniqueKeyValidation,
    validationError: targetTableUniqueKeyValidationError,
  } = useUniqueKeyValidation();
  const {
    isLoading: sourceTableUniqueKeyIsLoading,
    isValidated: sourceTableIsValidated,
    startUniqueKeyValidation: startSourceTableUniqueKeyValidation,
    validationError: sourceTableUniqueKeyValidationError,
  } = useUniqueKeyValidation();

  let baseTable: ExploreUserJoinTable | undefined;
  let targetTable: ExploreUserJoinTable | undefined;
  let join: ExploreUserJoin | undefined;
  if (queryPath.length > 0 && dataframe != null) {
    const targetTableName = queryPath[queryPath.length - 1];
    const baseTableName =
      queryPath.length === 1
        ? tablePathToDatasetName(dataframe.pathToTable, spec.tables)
        : queryPath[queryPath.length - 2];
    join = spec.joins?.find(
      (j) =>
        j.targetTable.name === targetTableName &&
        j.sourceTable.name === baseTableName,
    );
    baseTable = spec.tables?.find((t) => t.name === join?.sourceTable.name);
    targetTable = spec.tables?.find((t) => t.name === join?.targetTable.name);
  }

  useEffect(() => {
    if (
      latestMagicEventStatus !== MagicEventStatus.PENDING_REVIEW ||
      join == null ||
      baseTable == null ||
      targetTable == null ||
      dataframe == null
    ) {
      return;
    }
    void startTargetTableUniqueKeyValidation({
      columnName: targetTable.primaryKeyColumn.name,
      dataConnectionId: dataframe.connectionId,
      dataSourceTableId: targetTable.dataSourceTableId,
    });
    void startSourceTableUniqueKeyValidation({
      columnName: baseTable.primaryKeyColumn.name,
      dataConnectionId: dataframe.connectionId,
      dataSourceTableId: baseTable.dataSourceTableId,
    });
    void startJoinValidation({
      baseColumnName: join.sourceTableJoinColumn.name,
      baseDataSourceTableId: baseTable.dataSourceTableId,
      dataConnectionId: dataframe.connectionId,
      targetColumnName: join.targetTableJoinColumn.name,
      targetDataSourceTableId: targetTable.dataSourceTableId,
    });
  }, [
    dataframe,
    join,
    latestMagicEventStatus,
    queryPath,
    startJoinValidation,
    startSourceTableUniqueKeyValidation,
    startTargetTableUniqueKeyValidation,
    baseTable,
    targetTable,
  ]);
  const hasTimeout = joinTestResult?.hadValidationTimeout ?? false;
  const hasError =
    (joinTestResult?.dbError != null ||
      joinTestResult?.hasNoMatches ||
      sourceTableUniqueKeyValidationError != null ||
      targetTableUniqueKeyValidationError != null) ??
    false;

  const dataSourceTable = spec.tables?.find(
    (t) => t.name === join?.targetTable.name,
  );

  const existingJoinConfig = useMemo(() => {
    return dataSourceTable && join && baseTable && targetTable
      ? {
          targetTable: {
            pathToTable: dataSourceTable.pathToTable,
            dataSourceTableId: dataSourceTable.dataSourceTableId,
          },
          join,
          basePrimaryKey: baseTable.primaryKeyColumn,
          targetPrimaryKey: targetTable.primaryKeyColumn,
        }
      : undefined;
  }, [baseTable, dataSourceTable, join, targetTable]);

  const isLoading =
    isJoinValidationLoading ||
    targetTableUniqueKeyIsLoading ||
    sourceTableUniqueKeyIsLoading;
  useEffect(() => {
    dispatch(
      magicActions.setMagicExploreValidationLoading({
        cellId,
        data: isLoading,
      }),
    );
  }, [cellId, dispatch, isLoading]);
  let data: MagicExploreValidationResult | undefined = undefined;
  if (hasTimeout) {
    data = "TIMEOUT";
  } else if (hasError) {
    data = "ERROR";
  } else if (
    isJoinValidated &&
    targetTableIsValidated &&
    sourceTableIsValidated
  ) {
    data = "SUCCESS";
  }
  useEffect(() => {
    if (
      data != null &&
      latestMagicEventStatus === MagicEventStatus.PENDING_REVIEW
    ) {
      updateMagicJoinValidation(data);
    }
  }, [cellId, data, updateMagicJoinValidation, latestMagicEventStatus]);

  // If our validation determines a different type of join than what magic guessed,
  // just update it automatically here
  useEffect(() => {
    if (existingJoinConfig == null) return;
    if (relationshipType == null) return;

    if (relationshipType === existingJoinConfig.join.relationshipType) return;

    updateExploreSpec(
      exploreSpecActions.editUserJoin({
        basePrimaryKey: existingJoinConfig.basePrimaryKey,
        targetPrimaryKey: existingJoinConfig.targetPrimaryKey,
        sourceTableJoinColumn: existingJoinConfig.join.sourceTableJoinColumn,
        targetTableJoinColumn: existingJoinConfig.join.targetTableJoinColumn,
        joinToEdit: existingJoinConfig.join,
        relationshipType,
      }),
      // Since magic defaults to "to-many", we'll still have correct results
      // regardless of this spec update. So instead of kicking off a new run and resetting progress,
      // just let things keep running.
      { suppressAutoRun: true },
    );
  }, [relationshipType, existingJoinConfig, updateExploreSpec]);

  return {
    hasTimeout,
    hasError,
    isLoading,
    existingJoinConfig,
    hasMagicGeneratedJoin:
      latestMagicEventStatus != null && (spec.joins ?? []).length > 0,
  };
};
